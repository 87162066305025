@import url("https://fonts.googleapis.com/css?family=Lato:300,400,700,900");

body {
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  background: #000000;
  z-index: 10;
}

header section {
  max-width: 1300px;
  margin: auto;
  padding: 20px;
  background: #ffd800;
}

@media (max-width: 1515px) {
  header section {
    max-width: 100%;
  }
}

header section .logo {
  transform: translateX(-87px);
}

header section a {
  float: right;
  padding: 3px 0;
}

* {
  outline: none;
}

.slider-content .inner button {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: none;
  background: #ffd800;
  border-radius: 2px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: cursor;
}

.slider-content .inner button:hover {
  color: #ffffff;
  background: #222222;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}
.slider-content .inner a {
  -webkit-appearance: none;
  appearance: none;
  -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  border: none;
  background: #ffd800;
  border-radius: 2px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-family: "lora", serif;

  font-size: 1.2em;
  cursor: cursor;
  text-decoration: none;
  color: #262626;
}
@media (max-width: 500px) {
  .slider-content .inner a {
    -webkit-appearance: none;
    appearance: none;
    -webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    -webkit-transition: all 0.5s ease;
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.1));
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    border: none;
    background: #ffd800;
    border-radius: 2px;
    text-transform: uppercase;
    box-sizing: border-box;
    padding: 10px 20px;
    font-family: "lora", serif;

    font-size: 1em;
    cursor: cursor;
    text-decoration: none;
    color: #262626;
  }
}

.slider-content .inner a:hover {
  color: #262626;
  background: #ffe136;
  -webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.2));
}

.wrapper {
  max-width: 1335px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: auto;
}

.wrapper a {
  display: inline-block;
  margin: 5px;
}

.wrapper a:first-child {
  margin-left: 0;
}

.slider-wrapper {
  position: relative;
  height: 80vh;
  overflow: hidden;
}

.slide {
  height: 80vh;
  background-size: cover !important;
}

.slide::before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #77777724 30%, rgb(41, 53, 43));
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: url("../assets/images/next.svg") no-repeat center center / 16px;
  width: 52px;
  height: 52px;
  padding: 10px;

  text-indent: -9999px;
  cursor: pointer;
}

.previousButton:hover,
.nextButton:hover {
  background: url("../assets/images/next.svg") no-repeat center center / 16px;
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: -10px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -10px;
}

.slider-content {
  text-align: right;
}
@media (max-width: 500px) {
  .slide {
    background-position-x: 30% !important;
  }
}

.slider-content .inner {
  padding: 0 70px;
  box-sizing: border-box;
  position: absolute;
  width: 70%;
  top: 50%;
  left: 60%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.slider-content .inner button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}
.slider-content .inner a {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
  margin-right: 20px;
}

.slider-content .inner p {
  color: #ffffff;
  font-size: 1.6em;
  line-height: 1.5;
  margin: 20px;
  font-family: "source sans pro", sans-serif;
  margin-left: auto;
  max-width: 640px;
}
@media (max-width: 500px) {
  .slider-content .inner p {
    color: #ffffff;
    font-size: 1.2em;
    line-height: 1.1;
    margin: 20px;
    margin-right: 0px;
    font-family: "source sans pro", sans-serif;
    margin-left: auto;
    max-width: 90%;
  }
}
.slider-content section {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(50vh);
  }
}

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(50vh);
  }
  .slider-content .inner {
    width: 100%;
    padding: 0px 50px;
    left: 50%;
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
    margin-right: 0px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
    margin-right: 0px;
  }
}
