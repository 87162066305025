@media (max-width: 500px) {
  .aboutUs {
    width: 100%;
  }
  .aboutUs-a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px;
  }
  .aboutUs-a h1 {
    font-family: "lora", serif;
    font-size: 2.2em;
    color: #262626;
    margin: 20px 0px 20px 0px;
    padding: 10px;

    text-align: center;
  }
  .aboutUs-a h2 {
    font-family: "source sans pro", sans-serif;
    font-size: 1.5em;
    padding: 10px;
    color: #634949;
    margin: 10px 0px 10px 0px;

    margin-right: auto;
  }
  .aboutUs-a p {
    width: 100%;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.2em;
    color: #262626;
    padding: 10px;
    text-align: center;
    margin: 10px 0px;
    line-height: 1.4em;
  }
}
@media (min-width: 500px) {
  .aboutUs {
    width: 100%;
  }
  .aboutUs-a {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 90vh;
    margin-top: 120px;
  }
  .aboutUs-a h1 {
    font-size: 3em;
    color: #262626;
    margin: 20px 0px 20px 0px;
    padding: 20px;
    text-align: center;
    font-weight: 900;
  }
  .aboutUs-a h2 {
    font-family: "source sans pro", sans-serif;
    font-size: 2em;
    color: #262626;
    margin: 20px 0px 20px 0px;
    padding: 20px;
    margin-right: auto;
  }
  .aboutUs-a p {
    width: 80%;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 1.4em;
    color: #262626;
    text-align: center;
    margin: 10px;
    line-height: 1.5em;
  }
}
