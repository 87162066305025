@media (max-width: 500px) {
  .basic-product {
    width: 100%;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    margin: 5px 0px;
    align-items: center;
    border-radius: 2px;
    flex-direction: column;
  }
  .basic-product-a {
    width: 45%;
  }
  .basic-product-a img {
    width: 100%;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .basic-product p {
    color: #525252;
    font-weight: 400;
    font-family: "roboto", sans-serif;
    font-size: 0.8em;
    width: 100%;
    text-align: center;
    background-color: whitesmoke;
    padding: 5px 0px;
  }
  .basic-product h4 {
    font-size: 1em;
    color: #262626;
    margin: 5px;
    font-weight: 500;
  }
  .basic-product h2 {
    font-size: 1em;
    color: #262626;
    font-weight: 500;
    padding: 5px 0px;
    width: 100%;
    text-align: center;
  }
  .basic-product a {
    text-decoration: none;
  }
  .basic-product a:hover h2 {
    text-decoration: none;
    color: #15852d;
  }
  .basic-product h3 {
    color: #4d4848;
    font-weight: 400;
    font-size: 0.9em;
    margin: 5px 0px;
    padding: 8px 10px;
    text-align: center;
    border-top: 1px solid #d0d0d0;
    display: none;
  }
}
@media (min-width: 500px) {
  .basic-product {
    width: 250px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    display: flex;
    margin: 5px;
    align-items: center;
    border-radius: 2px;
    flex-direction: column;
  }
  .basic-product:hover {
    transform: scale(1.05);
  }
  .basic-product-a {
  }
  .basic-product-a img {
    width: 100%;
    border-top-right-radius: 2px;
    border-top-left-radius: 2px;
  }
  .basic-product p {
    color: #525252;
    font-weight: 400;
    font-family: "roboto", sans-serif;
    font-size: 0.8em;
    width: 100%;
    text-align: center;
    background-color: whitesmoke;
    padding: 5px 0px;
  }
  .basic-product h4 {
    font-size: 1em;
    color: #262626;
    margin: 4px;
    font-weight: 500;
  }
  .basic-product h2 {
    font-size: 1.1em;
    color: #262626;
    font-weight: 500;
    padding: 5px 0px;
    width: 100%;
    text-align: center;
  }
  .basic-product a {
    text-decoration: none;
  }
  .basic-product a:hover h2 {
    text-decoration: none;
    color: #15852d;
  }
  .basic-product h3 {
    color: #4d4848;
    font-weight: 400;
    font-size: 1em;
    margin: 5px 0px;
    padding: 8px 10px;
    text-align: center;
    border-top: 1px solid #d0d0d0;
    display: none;
  }
}
.products-list a {
  text-decoration: none;
}
